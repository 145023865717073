var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.hasCustomBackground ? "" : "backend-gray", "backend"],
      style: [_vm.backgroundImageStyle],
    },
    [
      _c("main", [
        _c("div", { staticClass: "row h-100" }, [
          _c(
            "div",
            { staticClass: "col-12 col-md-9 py-4 referee-confirm-container" },
            [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "row", staticStyle: { padding: "15px" } },
                    [
                      _c("img", {
                        staticStyle: {
                          display: "block",
                          "margin-left": "auto",
                          "margin-right": "auto",
                        },
                        attrs: {
                          src: require("@/assets/loading.svg"),
                          alt: "loading..",
                          width: "25%",
                        },
                      }),
                    ]
                  )
                : _c("div", { staticClass: "main-content" }, [
                    !_vm.confirmMatchInfo.canConfirm
                      ? _c("div", { staticClass: "row" }, [
                          _vm.confirmMatchInfo.isRefereeConfirmed
                            ? _c("div", { staticClass: "col-12" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "referee.confirmReferee.alreadyConfirmed"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.confirmMatchInfo.isConfirmTokenExpired
                            ? _c("div", { staticClass: "col-12" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "referee.confirmReferee.tokenInvalid"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _c("div", [
                          _c(
                            "div",
                            { staticClass: "club-info" },
                            [
                              _c(
                                "b-img",
                                _vm._b(
                                  {
                                    attrs: {
                                      rounded: "circle",
                                      src: _vm.confirmMatchInfo.clubLogo,
                                    },
                                  },
                                  "b-img",
                                  {
                                    blank: !_vm.confirmMatchInfo.clubLogo,
                                    blankColor: "#777",
                                    width: 100,
                                    height: 100,
                                    class: "m1 club-logo",
                                  },
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                          _c("h5", { staticClass: "col-12 text-center mt-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("referee.summary.title")) +
                                " "
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-12 text-center mt-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("referee.confirmReferee.detailsInfo")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12 text-center mt-4" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getMatchDate) +
                                  " " +
                                  _vm._s(_vm.confirmMatchInfo.matchName) +
                                  " " +
                                  _vm._s(
                                    _vm.$t("referee.confirmReferee.match")
                                  ) +
                                  " " +
                                  _vm._s(_vm.$t("referee.confirmReferee.at")) +
                                  " " +
                                  _vm._s(_vm.getMatchTime) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.confirmed,
                                  expression: "!confirmed",
                                },
                              ],
                              staticClass: "col-12 text-center mt-4",
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn btn-success",
                                  attrs: {
                                    disabled: _vm.confirmLoading,
                                    type: "button",
                                    variant: "success",
                                  },
                                  on: { click: _vm.showConfirmAssignement },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "referee.confirmReferee.clickToConfirm"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                  ]),
            ]
          ),
        ]),
      ]),
      _c("confirm-modal", {
        attrs: { message: _vm.$t("referee.confirmReferee.confirmQuestion") },
        on: { onConfirm: _vm.confirmAssignement },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }