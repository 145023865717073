






























































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { RefereeMatch } from "../models/referee-match.model";
import { ToastHelper } from "@/shared/helpers/toast.helper";
import { DateHelper } from "@/shared/helpers/date.helper";

@Component
export default class ConfirmReferee extends Vue {
    loading = false;
    confirmLoading = false;
    confirmed = false;

    @Getter
    confirmMatchInfo!: RefereeMatch;

    @Action
    loadConfirmMatch!: (payload: { token: string }) => Promise<void>;
    @Action
    confirmReferee!: (payload: { token: string }) => Promise<void>;

    async created(): Promise<void> {
        try {
            this.loading = true;
            await this.loadConfirmMatch({ token: this.token });
        } 
        catch (error) {
            ToastHelper.showError(
                this.$bvToast,
                this.$t("shared.toast.error")
            );
        } 
        finally {
            this.loading = false;
        }
    }

    async showConfirmAssignement(): Promise<void> {
        this.$bvModal.show('confirm-modal');
    }

    async confirmAssignement(): Promise<void> {        
        try {
            this.loading = true;

            await this.confirmReferee({ token: this.token });

            this.confirmed = true;

            ToastHelper.showSuccess(
                this.$bvToast,
                this.$t("referee.confirmReferee.confirmed")
            );
        } 
        catch (ex) {
            ToastHelper.showError(
                this.$bvToast,
                this.$t("shared.toast.error")
            );
        } 
        finally {
            this.loading = false;
        }
    }

    get token(): string {
        return this.$route.params.token;
    }
    
    get hasCustomBackground() : boolean {
        return this.confirmMatchInfo !== null 
            && this.confirmMatchInfo?.clubBackground !== null
            && this.confirmMatchInfo?.clubBackground !== '';
    }

    get backgroundImageStyle() : { backgroundImage: string, backgroundSize: string } {
        let imgSrc = '';

        if (this.loading) {
            imgSrc = '';
        }
        else if (this.hasCustomBackground) {
            imgSrc = 'url(' + this.confirmMatchInfo?.clubBackground + ')';
        }
        else {
            imgSrc = 'url(\'/bg2.jpg\')';
        }

        return {
            backgroundImage: imgSrc,
            backgroundSize: 'cover'
        };
    }

    get getMatchDate() : string {
        if (!this.confirmMatchInfo || !this.confirmMatchInfo?.startUtc) {
            return '';
        }

        return DateHelper.parseDate(this.confirmMatchInfo?.startUtc);
    }

    get getMatchTime() : string {
        if (!this.confirmMatchInfo || !this.confirmMatchInfo?.startUtc) {
            return '';
        }

        return DateHelper.parseTime(this.confirmMatchInfo?.startUtc);
    }
}
